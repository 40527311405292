import { ChainId } from 'sdk'
import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'utils/bigNumber'
import {ca} from "date-fns/locale";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://scan.pego.network',
  [ChainId.TESTNET]: 'https://explorer.pegotest.net/#',
  [ChainId.PG_TEST]: 'https://explorer.pegotest.net/#',
}

export const TOKEN_SLIPPAGE = {
  '0xdDab45b5df2031eF4195091167a9Ff0830211094':10,//PNS
  '0x1385Aa68AC960Abb0112aa5905FACE08EFe48053':0,//OSKDAO
  '0xf88634C560a4AA2c6034DAd4Dc5e931A65cdF954':2,//W3
  '0x5AbD5f6Bbe0B48E9179F6307eAea032d998B4a7e':2,//W3W
  '0xe28489df1c10465934Ac9FDF755a6cDF86cC1559':2,//W3B
  '0xCe721D6C56c628A5eBde4783abb9D70d2053AAb9':2,//W3K
  '0x0fcfC5DDde7A1f5C5C8bDF0Dc9D0E6109536B7D8':2,//4MW
}

export const AUTO_ALIPPAGE = [
  {
    'token0':'0x02F9Bebf5E54968D8Cc2562356C91ECDE135801B',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':20,
    'gasPrice':510
  },
  {
    'token0':'0x1385Aa68AC960Abb0112aa5905FACE08EFe48053',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':2230,
    'gasPrice':510
  },
  {
    'token0':'0xf88634C560a4AA2c6034DAd4Dc5e931A65cdF954',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':230,
    'gasPrice':510
  },
  {
    'token0':'0x5AbD5f6Bbe0B48E9179F6307eAea032d998B4a7e',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':230,
    'gasPrice':510
  },
  {
    'token0':'0xe28489df1c10465934Ac9FDF755a6cDF86cC1559',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':230,
    'gasPrice':510
  },
  {
    'token0':'0xCe721D6C56c628A5eBde4783abb9D70d2053AAb9',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':230,
    'gasPrice':510
  },
  {
    'token0':'0xdDab45b5df2031eF4195091167a9Ff0830211094',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':1030,
    'gasPrice':510
  },
  {
    'token0':'0xAf00188f8C398261E1a2fE626d342b19F16f74b3',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':30,
    'gasPrice':510
  },
  {
    'token0':'0x7151f6D2D74FEBF56103Ee0e28B1863B38B52eE0',
    'token1':'0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
    'slippage':230,
    'gasPrice':510
  }
]

export function getAutoAlippageValue(token0,token1){
  let slippage = 0;
  for (let i=0;i<AUTO_ALIPPAGE.length;i++){
    let item = AUTO_ALIPPAGE[i];
    if(item['token0'].toUpperCase() == token0.toUpperCase() && item['token1'].toUpperCase() == token1.toUpperCase()){
      slippage = item.slippage;
      break;
    }
    if(item['token0'].toUpperCase() == token1.toUpperCase() && item['token1'].toUpperCase() == token0.toUpperCase()){
      slippage = item.slippage;
      break;
    }
  }
  return slippage;
}

export function getAutoAlippage(token0,token1){
  let isAuto = false;
  for (let i=0;i<AUTO_ALIPPAGE.length;i++){
    let item = AUTO_ALIPPAGE[i];
    if(item['token0'].toUpperCase() == token0.toUpperCase() && item['token1'].toUpperCase() == token1.toUpperCase()){
      isAuto = true;
      break;
    }
    if(item['token0'].toUpperCase() == token1.toUpperCase() && item['token1'].toUpperCase() == token0.toUpperCase()){
      isAuto = true;
      break;
    }
  }
  return isAuto;
}

export function getTokenSlippage(address){
  if(TOKEN_SLIPPAGE[address] > 0){
    return TOKEN_SLIPPAGE[address]
  }
  return 0;
}

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 1
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://w3swap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
// export const DEFAULT_GAS_LIMIT = 300000
export const DEFAULT_GAS_LIMIT = 1000000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANCAKE_BUNNIES_UPDATE_FREQUENCY = 8000
